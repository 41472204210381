/* Chat UI Custom Theming */

.cs-message__sender-name,
.cs-message__sent-time {
	display: block !important;
}

.cs-message__sent-time {
	display: block !important;
}
